<template>
  <div class="top">
    <div class="topLeft">
      <router-link to="/">
        <img src="../assets/img/logo.png">
      </router-link>

    </div>
    <div class="topCenter">
    </div>
    <div class="topRight">
      <div v-if="!isLogged">
        <el-button @click="registerClick" type="primary" round plain>注册</el-button>
        <el-button @click="loginClick" type="primary" round>登录</el-button>
      </div>
      <div v-else>
        <el-dropdown @command="handleDropdown">
          <span class="el-dropdown-link">
           {{ user.name }}
            <i class="el-icon-arrow-down el-icon--right"></i>
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="profile">个人页面</el-dropdown-item>
            <el-dropdown-item command="playground">实验界面</el-dropdown-item>
            <el-dropdown-item divided>
              <el-button @click="logoutClick" type="danger" round>退出</el-button>
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>

      </div>
    </div>
  </div>
</template>

<script>

import {mapGetters} from "vuex";
import {mapState} from 'vuex';

export default {
  name: "top",
  computed: {
    ...mapGetters(['isLogged']),
    ...mapState(['user'])
  },

  methods: {
    registerClick() {
      this.$router.push("/register")
    },
    loginClick() {
      this.$router.push("/login")
    },
    logoutClick() {
      this.$store.dispatch("logout")
    },
    handleDropdown(command){
      console.log(command);
      switch (command) {
        case "profile":
          this.$router.push("/profile")
          break;
        case "playground":
          this.$router.push("/playground")
          break;
      }
    }
  }
}
</script>

<style scoped>
.top {
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #F2F6FC;
}

.topLeft {
  flex: 3;
  /*background-color: aqua;*/
  text-align: center;
  padding-left: 30px;
  padding-right: 30px;
}

.topCenter {
  flex: 6;
  /*background-color: cornflowerblue;*/
}

.topRight {
  flex: 3;
  text-align: right;
  padding-right: 30px;
  /*background-color: aquamarine;*/
}

.topLeft img {
  height: 30px;
}
</style>