import { getToken } from "@/utils/auth";
import axios from "axios";
// 创建axios的实例
const instance = axios.create({
  baseURL: "https://api.osedu.isrc.ac.cn",
  timeout: 20 * 1000,
});

// 请求拦截器
instance.interceptors.request.use(
  config => {
    const token = getToken();
    console.log("token:", token);
    if (token != null) {
      console.log("token:", token);
      const a = `Bearer ${token}`
      console.log("a",a)
      config.headers.common['Authorization'] = a;
    }
    return config;
  },
  err => {
    console.log(err);
  }
);

// 响应拦截器
instance.interceptors.response.use(
  (res) => {
    return res.data;
  },
  (err) => {
    console.log(err);
    if (err.response.status === 401) {
      this.$store.dispatch("logout");
    }
    return Promise.reject(err);
  }
);
export function request(config) {
  // 3.发送真正的网络请求
  return instance(config);
}


export function get(url, params) {
  return new Promise((resolve, reject) => {
    instance
      .get(url, {
        params: params,
      })
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err.data);
      });
  });
}

export function destroy(url) {
  return new Promise((resolve, reject) => {
    instance
      .delete(url)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err.data);
      });
  });
}

export function post(url, params) {
  return new Promise((resolve, reject) => {
    instance
      .post(url, params)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err.data);
      });
  });
}

export function put(url, params) {
  return new Promise((resolve, reject) => {
    instance
      .put(url, QS.stringify(params))
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err.data);
      });
  });
}
