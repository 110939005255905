<template>
  <div id="app">
    <top></top>
    <router-view></router-view>
  </div>
</template>
<script>
import { mapMutations } from "vuex";
import Top from "@/components/Top";
import { getToken } from "./utils/auth";
export default {
  name: "App",
  components: {
    Top,
  },
  created() {
    // const userInfo = localStorage.getItem('user')
    // if(userInfo){
    //   const userData = JSON.parse(userInfo)
    //   this.$store.commit('setUserData',userData)
    // }
    //自动登录（这样只要浏览器存储着token，即可登录
    if (getToken()) {
      this.getUser();
    }
  },
  methods: {
    ...mapMutations([
      "loginHandle"
    ]),
    async getUser() {
      let res = await this.$api.User.Detail();
      this.loginHandle(res)
      console.log("user:",res)
    },
  },
};
</script>
<style></style>
