import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import api from './network/request'
import ElementUI from "element-ui"
import VueContextMenu from 'vue-contextmenu'

import '@/assets/css/style.css'
import 'element-ui/lib/theme-chalk/index.css';

Vue.config.productionTip = false
Vue.prototype.$api = api;
Vue.use(ElementUI)
Vue.use(VueContextMenu)

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
