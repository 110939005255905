import Vue from 'vue'
import Vuex from 'vuex'
import {request} from "@/network/http";
import { setToken , removeToken} from '@/utils/auth';
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    user:null
  },
  getters: {
    isLogged(state){//通过state.user是否为空判断当前用户是否登录。
      return Boolean(state.user)
    }
  },
  mutations: {
    loginHandle(state,user){
      state.user = user;
    },
    setUserData(state,userData){
      console.log("userDAta",userData)
      state.user = userData.user
      // localStorage.setItem('user',JSON.stringify(userData))
      setToken(userData.token)
    },
    clearUserData(state){
      // localStorage.removeItem('user')
      state.user = null
      removeToken()
      location.reload()
    }
  },
  actions: {
    login({commit},credentials){
      return request({
          method: 'post',
          url: '/api/login',
          data: credentials
      }).then(data=>{
        commit('setUserData',data)
      })
    },
    logout({commit}){
      commit('clearUserData')
    }
  },
  modules: {
  }
})
