import { get, post, put, destroy } from "./http";

const Api = {
  User:{
    Detail(){
      return get('/api/user');
    }
  }
}

export default Api;