import { getToken } from '@/utils/auth'
import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'

Vue.use(VueRouter)

const Home = () => import("@/views/Home")
const Register = () => import("@/views/Register")
const Login = () => import("@/views/Login")
const Playground = () => import("@/views/Playground")
const Profile = () => import("@/views/Profile")
const Editor = () => import("@/views/Editor")
const Test = ()=>import("@/views/Test")

const routes = [
    {
        path: "/",
        component: Home,
        meta: {requiresAuth: false}
    },    {
        path: "/test",
    component: Test,
    meta: {requiresAuth: false}
},
    {
        path: "/register",
        component: Register,
        meta: {requiresAuth: false}
    },
    {
        path: "/login",
        component: Login,
        meta: {requiresAuth: false}
    }, {
        path: "/playground",
        component: Playground,
        meta: {requiresAuth: true}
    }, {
        path: "/profile",
        component: Profile,
        meta: {requiresAuth: true}
}, {
        path: "/editor",
    component: Editor,
    meta: {requiresAuth: true}
    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

router.beforeEach(async (to, from, next) => {
    if (to.matched.some(record => record.meta.requiresAuth)) {
        // 若目标页面需要登录才可以访问
        console.log("beforeeach,record:")
        
        if (!store.state.user) {
            //且用户未登录
            next("/login");
        } else {
            next()
        }
    } else {
        //目标页面无需权限即可访问
        console.log("beforeeach,no record:")
        if(to.path == '/login' && store.state.user){//登录界面要求必须未登录
            next({path:'/'})
        }
        next()
    }
})

export default router
